<template>
  <div>
    <slot name="button" :openPanel="openPanel"> </slot>
    <b-sidebar
      id="sidebar-product"
      width="450px"
      backdrop-variant="dark"
      backdrop
      right
      shadow
      v-model="visible"
      data-anima="sidebar"
      @hidden="resetUrl"
    >
      <b-container fluid class="container-sidebar">
        <b-row class="panel">
          <b-col>
            <b-overlay :show="loading">
              <div class="top-bar">
                <!-- <BaseButton
                  variant="link-info"
                  @click="editProduct(product.id, product.slug)"
                  >Editar produto</BaseButton
                > -->
              </div>
              <b-row>
                <div
                  class="aprovacao btn-2"
                  v-if="product.status == 'REQUESTED'"
                >
                  <img src="@/assets/img/icons/info-prod.svg" />
                  {{ $t('shared.text_2022') }}
                </div>
              </b-row>
              <b-row class="item-dados">
                <div class="product-main">
                  <div class="img-product">
                    <img
                      :src="product.images[0].path"
                      v-if="!loading && product.images.length"
                    />
                  </div>
                  <div class="name-product">
                    <span>{{ $t('shared.text_2023') }} #{{ product.id }}</span>
                    <p>{{ product.name }}</p>
                  </div>
                </div>
              </b-row>

              <!-- Detalhes -->
              <b-row class="item-dados">
                <b-col>
                  <!-- <h5 class="mb-2">Detalhes</h5> -->
                  <div class="detalhe-item">
                    <img src="@/assets/img/icons/tipo.svg" />

                    <div class="info-detalhe">
                      <span>{{ $t('shared.text_2024') }}</span>
                      <p>
                        {{
                          product.type == "SUBSCRIPTION"
                            ? $t('shared.text_2025')
                            : $t('shared.text_2026')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="detalhe-item">
                    <img src="@/assets/img/icons/valor.svg" />
                    <div class="info-detalhe">
                      <span>{{ $t('shared.text_2027') }}</span>
                      <p>
                        {{ filterCurrency(product.currency_id) }}
                        {{ formatMoney(product.amount) }}
                      </p>
                    </div>
                  </div>

                  <div class="detalhe-item">
                    <img src="@/assets/img/icons/comissao.svg" />
                    <div class="info-detalhe">
                      <span>{{ $t('shared.text_2028') }}</span>
                      <p>
                        {{
                          product.affiliation
                            ? `${$t('shared.text_2029')} (${product.comission}%)`
                            : $t('shared.text_2030')
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="detalhe-item" v-if="product.format">
                    <img src="@/assets/img/icons/formato.svg" />
                    <div class="info-detalhe">
                      <span>{{ $t('shared.text_2031') }}</span>
                      <p>
                        {{ getTypeProduct(product.format) }}
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <!-- Avaliação -->
              <b-row class="item-dados">
                <b-col class="mt-3">
                  <div class="avaliacao-item">
                    <img src="@/assets/img/icons/my-sales.svg" />
                    {{ product.sales }} {{ $t('shared.text_2032') }}
                  </div>
                  <!-- <div class="avaliacao-item">
                    <img src="@/assets/img/icons/affiliate.svg" class="mr-1" />
                    {{ product.affiliates }} Afiliados Atribuidos
                  </div> -->
                </b-col>
              </b-row>

              <!-- Avaliação -->
              <b-row class="item-dados" v-if="product.rating_count">
                <b-col class="mt-3">
                  <h5 class="titulo-item">{{ $t('shared.text_2033') }}</h5>

                  <div class="avaliacao-item">
                    <b-form-rating
                      id="rating"
                      v-model="product.rating_avg"
                      variant="warning"
                      class="mb-2"
                      readonly
                      size="lg"
                    ></b-form-rating>
                    <b-tooltip
                      target="rating"
                      :title="`${$t('shared.text_2034')} ${product.rating_avg} | Maior: ${product.rating_max} | Menor: ${product.rating_low}`"
                    />
                  </div>

                  <div class="avaliacao-item">
                    {{ product.rating_count }}
                    {{
                      product.rating_count > 1
                        ? $t('shared.text_2035')
                        : $t('shared.text_2036')
                    }}
                  </div>
                </b-col>
              </b-row>

              <!-- Links -->
              <b-row class="mt-4 item-dados">
                <b-col>
                  <h5 class="titulo-item">{{ $t('shared.text_2037') }}</h5>

                  <div class="label-campo" v-if="link_checkout">
                    Checkout
                    <div class="item-link-detalhes">
                      <input type="text" readonly :value="link_checkout" />
                      <a
                        href="#"
                        class="btn-copy-link"
                        @click.prevent="copyAddress(link_checkout)"
                        >{{ $t('shared.text_2038') }}</a
                      >
                    </div>
                  </div>

                  <div class="label-campo" v-if="link_pagina_vendas">
                    {{ $t('shared.text_2039') }}
                    <div class="item-link-detalhes">
                      <input type="text" readonly :value="link_pagina_vendas" />
                      <a
                        href="#"
                        class="btn-copy-link"
                        @click.prevent="copyAddress(link_pagina_vendas)"
                        >{{ $t('shared.text_2038') }}</a
                      >
                    </div>
                  </div>

                  <!-- <div v-if="product.links.length"> -->
                  <div>
                    <div v-for="link in product.links" :key="link.id">
                      <div class="label-campo" v-if="link.name != 'MAIN'">
                        {{ link.name }}
                        <div class="item-link-detalhes">
                          <input type="text" readonly :value="appendAffiliateId(link)" />
                          <a
                            href="#"
                            class="btn-copy-link"
                            @click.prevent="copyAddress(appendAffiliateId(link))"
                            >{{ $t('shared.text_2038') }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="label-campo"
                    v-if="!isAffiliate && product.affiliation"
                  >
                    {{ $t('shared.text_2040') }}
                    <div class="item-link-detalhes">
                      <input type="text" readonly :value="link_afiliado" />

                      <a
                        v-if="link_afiliado == '---'"
                        href="#"
                        class="btn-copy-link"
                        @click.prevent="affiliateLink"
                        >{{ $t('shared.text_2041') }}</a
                      >

                      <a
                        v-if="link_afiliado != '---'"
                        href="#"
                        class="btn-copy-link"
                        @click.prevent="copyAddress(link_afiliado)"
                        >{{ $t('shared.text_2038') }}</a
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </b-sidebar>
  </div>
</template>
<script>
import ProductService from "@/services/resources/ProductService";
import moment from "moment-timezone";
const service = ProductService.build();
import Money from "@/mixins/money";
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();
import { symbolCurrency } from "@/helpers.js";

export default {
  name: "DetalhesProduto",
  data() {
    return {
      loading: true,
      visible: false,
      sales_page: null,
      product: {
        name: "",
        description: "",
        instructions: "",
        images: [{ path: null }],
      },
      link_afiliado: "---",
      link_checkout: "",
      link_pagina_vendas: "",
    };
  },
  props: {
    isAffiliate: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [Money],
  filters: {
    date(date) {
      return moment.tz(date, "America/Sao_Paulo").format("DD/MM/YYYY H:m");
    },
  },
  computed: {
    userID() {
      return this.$store.getters.getProfile.id;
    },
    maxLength() {
      return this.$store.getters["sidebarState"] ? 20 : 30;
    },
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
  },
  methods: {
    filterCurrency(id) {
      if (id) {
        return symbolCurrency(id);
      }
      return "R$";
    },
    getTypeProduct(type) {
      let t = "";
      if (type) {
        switch (type.toUpperCase()) {
          case "INFOPRODUCT": {
            t = this.$t('shared.text_2042');
            break;
          }
          case "SERVICE": {
            t = this.$t('shared.text_2043');
            break;
          }
          case "PHYSICALPRODUCT": {
            t = this.$t('shared.text_2044');
            break;
          }
          case "EVENT": {
            t = this.$t('shared.text_2045');
            break;
          }
          case "OTHERS": {
            t = this.$t('shared.text_2046');
            break;
          }
          default: {
            t = type;
            break;
          }
        }
        return t;
      } else return type;
    },
    editProduct(id_product) {
      this.$router.push(`/produtos/edicao-produto/${id_product}`);
    },
    affiliateLink() {
      let data = {
        product_id: this.product.id,
        url: "invite",
      };

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.link_afiliado = `${process.env.VUE_ADM_PAGE}/recrutamento/${response.hash}/?p_id=${this.product.id}`;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    resetUrl() {
      if (Object.values(this.$route.query).length) {
        this.$router.push({ query: {} });
      }
      this.link_afiliado = "---";
    },

    openPanel(id, sales_page, affiliate = false) {
      this.visible = true;
      this.sales_page = sales_page;
      this.fetchProduct(id, affiliate);
    },
    copyAddress(href) {
      const el = document.createElement("textarea");
      el.value = href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$bvToast.toast(this.$t('shared.text_2047'), {
        variant: "success",
        title: this.$t('shared.text_2048'),
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    appendAffiliateId(link){
      return `${this.baseURL}/redirect/${link.id}/${this.isAffiliate ? `?a_id=${this.userID}` : ''}`
    },
    fetchProduct(id, affiliate) {
      this.product = {
        name: "",
        description: "",
        instructions: "",
        images: [{ path: null }],
      };
      this.loading = true;

      let data = {
        url: id,
        id: id,
      };

      if (affiliate) {
        data.affiliate_id = this.userID;
      }
      
      service
        .search(data)
        .then((response) => {
          this.loading = false;
          this.product = response.data;
          
          if (this.isAffiliate) {
            this.link_checkout =`${this.baseURL}/${this.product.id}/?a_id=${this.userID}`
          } else {
            this.link_checkout = this.baseURL + "/" + this.product.id;
          }
          this.link_pagina_vendas = this.product.links.length
            ? `${this.baseURL}/redirect/${this.product.links[0].id}/${this.isAffiliate ? `?a_id=${this.userID}` : ''}`
            : "";

          this.product.instructions = this.product.instructions || "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.panel {
  .sumary {
    background: rgb(240, 240, 240);
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .product-name {
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0 0 0;
  }

  .product-image {
    height: 170px;
    overflow: hidden;
    border-radius: 5px;
    background: #f5f2fc;
    display: flex;
    align-items: center;
    object-fit: contain;
  }
  .thumb-fake {
    width: 100%;
    color: silver;
    font-size: 32px;
    letter-spacing: 2px;
    justify-content: center;
  }
}
.container-sidebar {
  padding: 40px;
  padding-top: 20px;
}
.detalhe-item {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  align-items: center;
}
.detalhe-item + .detalhe-item {
  margin-top: 15px;
}
// .detalhe-item:first-child img,
// .detalhe-item:last-child {
//   margin-right: 23px;
// }
// .detalhe-item:nth-child(2) img {
//   margin-right: 28px;
// }
// .detalhe-item:nth-child(3) img {
//   margin-right: 20px;
// }
// .detalhe-item:nth-child(4) img {
//   margin-right: 20px;
// }
.detalhe-item a {
  color: #2a63ab;
}

.info-detalhe span {
  font-size: 11px;
  color: #81858e;
}
.info-detalhe p {
  font-weight: 600;
  margin-top: 3px;
  font-size: 15px;
}

header.b-sidebar-header {
  padding: 30px !important;
  padding-bottom: 0 !important;
}
.item-dados {
  padding: 22px 0;
}
.item-dados + .item-dados {
  border-top: 0.5px solid #ededf0;
}
.titulo-item {
  font-size: 18px;
  font-weight: 600;
  color: $black;
  margin-bottom: 15px;
}
.label-campo {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}
.label-campo span {
  font-weight: normal;
  margin-left: 5px;
}
.label-campo + .label-campo {
  margin-top: 5px;
}
.btn-flex {
  display: flex;
  flex-direction: column;
}
.btn-flex button {
  width: 100%;
  height: 55px;
}
.item-link-detalhes {
  margin-top: 15px;
  position: relative;
}
.item-link-detalhes input {
  width: 76%;
  font-size: 13px !important;
}
.item-link-detalhes input:hover,
.item-link-detalhes input:focus {
  border-color: #2a63ab !important;
}
.btn-copy-link {
  position: absolute;
  right: 2px;
  top: 0;
  padding: 15px;
  color: rgb(0, 46, 116);
  background: rgb(255, 255, 255);
  border: 1px solid rgb(85, 146, 213);

  font-size: 13px;
  border-radius: 5px;
}
.btn-copy-link:hover {
  text-decoration: none;
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-bar button {
  height: 45px;
  padding: 0 25px !important;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.04);
}
// aprovação
.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.avaliacao-item {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 20px;
  font-size: 14px;
}
.avaliacao-item + .avaliacao-item {
  margin-top: 25px;
}
.product-main {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  gap: 20px;
  align-items: center;
}
.img-product {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-product,
.img-product img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: cover;
}
.name-product span {
  font-size: 11px;
  color: #81858e;
}
.name-product p {
  font-weight: 600;
}

@media (max-width: 640px) {
  .item-link-detalhes input {
    width: 74%;
  }
}

@media (max-width: 320px) {
  .item-link-detalhes input {
    width: 63%;
  }
}

</style>
