<template>
  <BaseModal
    name="comission-affiliate"
    size="md"
    :title="$t('seller.affiliation.comission_affiliate.text_121')"
    @hidden="reset"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <section class="container-arquivos" v-else>
      <div class="item-response" v-if="dados.affiliate">
        <p>{{ $t('seller.affiliation.comission_affiliate.text_1658') }}</p>
        <span>{{ dados.affiliate.name }}</span
        ><br />
        <span>{{ dados.affiliate.email }}</span>
      </div>

      <b-row v-if="dados.affiliate">
        <b-col cols="12" md="6" class="mt-2">
          <b-form-group :label="$t('seller.affiliation.comission_affiliate.text_1659')" label-for="comission">
            <b-form-input
              name="comission"
              id="comission"
              :placeholder="$t('seller.affiliation.comission_affiliate.text_1659')"
              v-model="comission"
              type="number"
              v-validate="'required|numeric|min_value:1|max_value:99'"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('comission')">
              {{ $t('seller.affiliation.comission_affiliate.text_1660') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="!dados.affiliate" class="item-response">
        <span>{{ $t('seller.affiliation.comission_affiliate.text_122') }}</span>
      </div>
    </section>
    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        {{ $t('seller.affiliation.comission_affiliate.text_1661') }}
      </BaseButton>

      <BaseButton variant="info2" :disabled="loading" @click="onSubmit">
        {{ $t('seller.affiliation.comission_affiliate.text_1662') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

export default {
  name: "ComissaoAfiliado",

  data() {
    return {
      loading: false,
      comission: 0,
    };
  },
  props: {
    dados: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    openModal() {
      this.loading = true;
      this.comission = this.dados.comission || this.dados.product.comission;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    reset() {
      this.comission = 0;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {
            product_id: this.dados.product_id,
            affiliate_id: this.dados.affiliate_id,
            comission: +this.comission,
          };

          serviceAffiliate
            .create(data, "/comission")
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast(this.$t('seller.affiliation.comission_affiliate.text_123'), {
                  title: this.$t('seller.affiliation.comission_affiliate.text_1663'),
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.$emit("close");
              this.$bvModal.hide("comission-affiliate");
              this.reset();
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.item-response {
  margin-bottom: 10px;
}
.item-response p {
  color: #333;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 10px;
}
.item-response span {
  color: #81858e;
  font-size: 14px;
  margin: 7px 0;
}
</style>
